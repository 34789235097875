import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RelatedPost from "../../../components/Blog/RelatedPost";
import Metadata from "../../../components/MetaData"; // Adjust path as needed

const SingleBlogPage = () => {
  const { id } = useParams();
  const [metadata, setMetadata] = useState({
    title: "Blog Details Page - Solid SaaS Boilerplate",
    description: "This is Blog details page for Solid Pro",
  });
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BLOG_BACKEND_URL}/blog/${id}`);
        if (!response.ok) {
          const text = await response.text();
          console.error("Error response:", text);
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        console.log("Fetched blog data:", data);

        const formattedContent = data.content
          .replace(/### (.*?)\n/g, "<h3>$1</h3>\n")
          .replace(/## (.*?)\n/g, "<h2>$1</h2>\n")
          .replace(/(\d+\.\s)/g, "<ol><li>$1")
          .replace(/\n\n/g, "</p><p>")
          .replace(/<\/p><p>(\d+\.\s)/g, "</li><li>$1")
          .replace(/<\/p><p>$/, "</p>")
          .replace(/<\/li><li>/g, "</li>\n<li>")
          .concat("</ol>");

        setBlog({ ...data, content: `<p>${formattedContent}</p>` });
        setMetadata({
          title: data.title,
          description: data.content.substring(0, 150),
        });
      } catch (err) {
        console.error("Failed to fetch blog:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Metadata title={metadata.title} description={metadata.description} />
      <section className="pt-20 pb-20 lg:pt-24 lg:pb-24 xl:pt-30 xl:pb-30">
        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
          <div className="flex flex-col-reverse gap-7.5 lg:flex-row lg:gap-12.5">
            <aside className="md:w-1/2 lg:w-1/3">
              <div className="animate-fade mb-10 rounded-md border border-stroke bg-white p-9 shadow-lg dark:border-strokedark dark:bg-blacksection mr-6 mt-4">
                <h4 className="mb-7.5 text-2xl font-semibold text-black  mb-2">Categories</h4>
                <ul>
                  {["News", "Proxies", "Business"].map((category) => (
                    <li key={category} className="mb-3 last:mb-0 transition-all duration-300 hover:text-primary hover:underline">
                      <a href="/">{category}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <RelatedPost />
            </aside>
            <main className="lg:w-2/3 mt-4">
              <article className="p-5 animate-fade rounded-md border border-stroke bg-white p-7.5 shadow-lg dark:border-strokedark dark:bg-blacksection md:p-10">
                <h2 className="mb-5 text-3xl font-semibold text-black  xl:text-4xl">{blog.title}</h2>
                <ul className="mb-9 flex flex-wrap gap-5 xl:gap-7.5">
                  <li>
                    <span className="text-black ">Author:</span> {blog.author || "Unknown"}
                  </li>
                  <li>
                    <span className="text-black ">Published On:</span> {blog.publishedDate || "Unknown"}
                  </li>
                  <li>
                    <span className="text-black ">Category:</span> {blog.category || "General"}
                  </li>
                </ul>
                <div className="blog-details">
                  <div dangerouslySetInnerHTML={{ __html: blog.content }} />
                </div>
              </article>
            </main>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleBlogPage;
