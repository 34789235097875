import React, { createContext, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HeroHome from "./pages/hero-home";
import Features from "./pages/Features";
import Pricing from "./pages/Pricing";
import Brands from "./components/Brands";
import FeaturePage from "./pages/FeaturesPage";
import PricingPage from "./pages/PricingPage";
import FAQ from "./pages/FAQ";
import TOS from "./pages/TOS";
import Privacy from "./pages/Privacy";
import SingleBlogPage from "./pages/Blog/Blog-datails/Page";
import BlogPage from "./pages/Blog/Page";
import Blog from "../src/components/blog";
import FreeProxy from "../src/pages/FreeProxy";
import axios from "axios";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { processSettingsToValues } from "./utils/helper";

const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppWrapper />
    </QueryClientProvider>
  );
}

export const SettingsContext = createContext();

const AppWrapper = () => {
  const [settings, setSettings] = useState(null);

  const fetchSettings = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/landing/settings`);
      if (res.status === 200) {
        return res.data.payload.settings;
      }
    } catch (error) {
      throw new Error("Unable to fetch settings");
    }
  };

  const { data, error, isLoading } = useQuery({ queryKey: ["settings"], queryFn: () => fetchSettings() });

  useEffect(() => {
    const process = async () => {
      const res = await processSettingsToValues(data);

      setSettings(res);
    };

    if (data) {
      process();
    }
  }, [data]);

  return (
    <SettingsContext.Provider value={settings}>
      <Router>
        <div className="App">
          {/* Header remains consistent across all pages */}
          <Header />

          {/* Routing logic */}
          <Routes>
            {/* Landing page route */}
            <Route
              path="/"
              element={
                <>
                  <HeroHome />
                  <Brands />
                  <Features />
                  <Pricing />
                  <Blog />
                </>
              }
            />

            {/* Features page route */}
            <Route path="/feature" element={<FeaturePage />} />

            {/* Pricing page route */}
            <Route path="/pricing" element={<PricingPage />} />

            <Route path="/blog" element={<BlogPage />} />

            <Route path="/blog/:id" element={<SingleBlogPage />} />

            <Route path="/FAQ" element={<FAQ />} />
            <Route path="/proxylist" element={<FreeProxy />} />

            <Route path="/tos" element={<TOS />} />

            <Route path="/Privacy" element={<Privacy />} />
          </Routes>

          {/* Footer remains consistent across all pages */}
          <Footer />
        </div>
      </Router>
    </SettingsContext.Provider>
  );
};

export default App;
