import React, { useEffect, useState } from "react";

const ProxyList = () => {
  const [proxies, setProxies] = useState([]);
  const [visibleProxies, setVisibleProxies] = useState([]);
  const [error, setError] = useState("");
  const [visibleCount, setVisibleCount] = useState(15);
  const [filters, setFilters] = useState({
    ssl: null,
    protocol: null,
    country: null,
  });
  const [tempFilters, setTempFilters] = useState({ ...filters });
  const [downloadType, setDownloadType] = useState("all");
  const [countries, setCountries] = useState([]);
  const [countryCodes, setCountryCodes] = useState({}); // To store countryCode to country mapping

  useEffect(() => {
    fetchProxies();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [proxies, filters, visibleCount]);

  const fetchProxies = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BLOG_BACKEND_URL}/proxies/getProxies`);
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }
      const data = await response.json();

      // Access the proxies array from the response data
      if (data && Array.isArray(data.proxies)) {
        setProxies(data.proxies);

        // Extract unique country codes from the proxies list
        const uniqueCountries = [...new Set(data.proxies.map((proxy) => proxy.countryCode || "Unknown"))];
        setCountries(uniqueCountries); // Set country options based on countryCode

        // Map country codes to country names (replace with actual country code mapping logic)
        const countryCodeMapping = uniqueCountries.reduce((acc, countryCode) => {
          acc[countryCode] = getCountryNameFromCode(countryCode); // This function can convert code to country
          return acc;
        }, {});
        setCountryCodes(countryCodeMapping);
      } else {
        setError("No proxies available or invalid format");
      }
    } catch (err) {
      console.error("Error:", err);
      setError("Failed to fetch proxies");
    }
  };

  const applyFilters = () => {
    const filteredProxies = proxies.filter((proxy) => {
      const sslFilter = filters.ssl === null || proxy.ssl === filters.ssl;
      const protocolFilter = filters.protocol === null || proxy.protocol === filters.protocol;
      const countryFilter = filters.country === null || proxy.countryCode === filters.country; // Compare with 'proxy.countryCode'

      return sslFilter && protocolFilter && countryFilter;
    });

    setVisibleProxies(filteredProxies.slice(0, visibleCount));
  };

  const loadMoreProxies = () => {
    setVisibleCount((prevCount) => prevCount + 30);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    const newValue = name === "ssl" ? (value === "true" ? true : value === "false" ? false : null) : value;
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value === "all" ? null : newValue,
    }));
  };

  const handleApplyFilters = () => {
    setFilters(tempFilters);
  };

  const handleDownload = () => {
    let content = "";
    const selectedOption = downloadType;

    if (selectedOption === "all") {
      content = JSON.stringify(proxies, null, 2);
    } else if (selectedOption === "ip:port") {
      content = proxies.map((proxy) => `${proxy.ip}:${proxy.port}`).join("\n");
    } else {
      return;
    }

    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = selectedOption === "all" ? "all_proxies.json" : "proxies_ip_port.txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Helper function to get the country name from countryCode
  const getCountryNameFromCode = (code) => {
    const countryNames = {
      AD: "Andorra",
      AE: "United Arab Emirates",
      AF: "Afghanistan",
      AG: "Antigua and Barbuda",
      AI: "Anguilla",
      AL: "Albania",
      AM: "Armenia",
      AO: "Angola",
      AR: "Argentina",
      AS: "American Samoa",
      AT: "Austria",
      AU: "Australia",
      AW: "Aruba",
      AX: "Åland Islands",
      AZ: "Azerbaijan",
      BA: "Bosnia and Herzegovina",
      BB: "Barbados",
      BD: "Bangladesh",
      BE: "Belgium",
      BF: "Burkina Faso",
      BG: "Bulgaria",
      BH: "Bahrain",
      BI: "Burundi",
      BJ: "Benin",
      BL: "Saint Barthélemy",
      BM: "Bermuda",
      BN: "Brunei Darussalam",
      BO: "Bolivia",
      BQ: "Bonaire, Sint Eustatius and Saba",
      BR: "Brazil",
      BS: "Bahamas",
      BT: "Bhutan",
      BV: "Bouvet Island",
      BW: "Botswana",
      BY: "Belarus",
      BZ: "Belize",
      CA: "Canada",
      CC: "Cocos (Keeling) Islands",
      CD: "Congo (Democratic Republic)",
      CF: "Central African Republic",
      CG: "Congo (Republic)",
      CH: "Switzerland",
      CI: "Côte d'Ivoire",
      CK: "Cook Islands",
      CL: "Chile",
      CM: "Cameroon",
      CN: "China",
      CO: "Colombia",
      CR: "Costa Rica",
      CU: "Cuba",
      CV: "Cabo Verde",
      CW: "Curaçao",
      CX: "Christmas Island",
      CY: "Cyprus",
      CZ: "Czech Republic",
      DE: "Germany",
      DJ: "Djibouti",
      DK: "Denmark",
      DM: "Dominica",
      DO: "Dominican Republic",
      DZ: "Algeria",
      EC: "Ecuador",
      EE: "Estonia",
      EG: "Egypt",
      EH: "Western Sahara",
      ER: "Eritrea",
      ES: "Spain",
      ET: "Ethiopia",
      FI: "Finland",
      FJ: "Fiji",
      FM: "Federated States of Micronesia",
      FO: "Faroe Islands",
      FR: "France",
      GA: "Gabon",
      GB: "United Kingdom",
      GD: "Grenada",
      GE: "Georgia",
      GF: "French Guiana",
      GG: "Guernsey",
      GH: "Ghana",
      GI: "Gibraltar",
      GL: "Greenland",
      GM: "Gambia",
      GN: "Guinea",
      GP: "Guadeloupe",
      GQ: "Equatorial Guinea",
      GR: "Greece",
      GT: "Guatemala",
      GU: "Guam",
      GW: "Guinea-Bissau",
      GY: "Guyana",
      HK: "Hong Kong",
      HM: "Heard Island and McDonald Islands",
      HN: "Honduras",
      HR: "Croatia",
      HT: "Haiti",
      HU: "Hungary",
      ID: "Indonesia",
      IE: "Ireland",
      IL: "Israel",
      IM: "Isle of Man",
      IN: "India",
      IO: "British Indian Ocean Territory",
      IQ: "Iraq",
      IR: "Iran",
      IS: "Iceland",
      IT: "Italy",
      JE: "Jersey",
      JM: "Jamaica",
      JO: "Jordan",
      JP: "Japan",
      KE: "Kenya",
      KG: "Kyrgyzstan",
      KH: "Cambodia",
      KI: "Kiribati",
      KM: "Comoros",
      KN: "Saint Kitts and Nevis",
      KP: "North Korea",
      KR: "South Korea",
      KW: "Kuwait",
      KY: "Cayman Islands",
      KZ: "Kazakhstan",
      LA: "Laos",
      LB: "Lebanon",
      LC: "Saint Lucia",
      LI: "Liechtenstein",
      LK: "Sri Lanka",
      LR: "Liberia",
      LS: "Lesotho",
      LT: "Lithuania",
      LU: "Luxembourg",
      LV: "Latvia",
      LY: "Libya",
      MA: "Morocco",
      MC: "Monaco",
      MD: "Moldova",
      ME: "Montenegro",
      MF: "Saint Martin",
      MG: "Madagascar",
      MH: "Marshall Islands",
      MK: "North Macedonia",
      ML: "Mali",
      MM: "Myanmar",
      MN: "Mongolia",
      MO: "Macau",
      MP: "Northern Mariana Islands",
      MQ: "Martinique",
      MR: "Mauritania",
      MS: "Montserrat",
      MT: "Malta",
      MU: "Mauritius",
      MV: "Maldives",
      MW: "Malawi",
      MX: "Mexico",
      MY: "Malaysia",
      MZ: "Mozambique",
      NA: "Namibia",
      NC: "New Caledonia",
      NE: "Niger",
      NF: "Norfolk Island",
      NG: "Nigeria",
      NI: "Nicaragua",
      NL: "Netherlands",
      NO: "Norway",
      NP: "Nepal",
      NR: "Nauru",
      NU: "Niue",
      NZ: "New Zealand",
      OM: "Oman",
      PA: "Panama",
      PE: "Peru",
      PF: "French Polynesia",
      PG: "Papua New Guinea",
      PH: "Philippines",
      PK: "Pakistan",
      PL: "Poland",
      PM: "Saint Pierre and Miquelon",
      PN: "Pitcairn Islands",
      PR: "Puerto Rico",
      PT: "Portugal",
      PW: "Palau",
      PY: "Paraguay",
      QA: "Qatar",
      RE: "Réunion",
      RO: "Romania",
      RS: "Serbia",
      RU: "Russia",
      RW: "Rwanda",
      SA: "Saudi Arabia",
      SB: "Solomon Islands",
      SC: "Seychelles",
      SD: "Sudan",
      SE: "Sweden",
      SG: "Singapore",
      SH: "Saint Helena, Ascension and Tristan da Cunha",
      SI: "Slovenia",
      SJ: "Svalbard and Jan Mayen",
      SK: "Slovakia",
      SL: "Sierra Leone",
      SM: "San Marino",
      SN: "Senegal",
      SO: "Somalia",
      SR: "Suriname",
      SS: "South Sudan",
      ST: "São Tomé and Príncipe",
      SV: "El Salvador",
      SX: "Sint Maarten",
      SY: "Syria",
      SZ: "Eswatini",
      TC: "Turks and Caicos Islands",
      TD: "Chad",
      TF: "French Southern and Antarctic Lands",
      TG: "Togo",
      TH: "Thailand",
      TJ: "Tajikistan",
      TK: "Tokelau",
      TL: "Timor-Leste",
      TM: "Turkmenistan",
      TN: "Tunisia",
      TO: "Tonga",
      TR: "Turkey",
      TT: "Trinidad and Tobago",
      TV: "Tuvalu",
      TZ: "Tanzania",
      UA: "Ukraine",
      UG: "Uganda",
      UM: "United States Minor Outlying Islands",
      US: "United States",
      UY: "Uruguay",
      UZ: "Uzbekistan",
      VA: "Vatican City",
      VC: "Saint Vincent and the Grenadines",
      VE: "Venezuela",
      VG: "British Virgin Islands",
      VI: "United States Virgin Islands",
      VN: "Vietnam",
      VU: "Vanuatu",
      WF: "Wallis and Futuna",
      WS: "Samoa",
      YE: "Yemen",
      YT: "Mayotte",
      ZA: "South Africa",
      ZM: "Zambia",
      ZW: "Zimbabwe",
    };
    return countryNames[code] || "Unknown";
  };

  return (
    <div className="min-h-min flex flex-col items-center px-4 font-sans font-normal">
      {error && <p className="text-red-500 text-lg mb-4">{error}</p>}

      {/* Filter Options */}
      <div className="w-full max-w-5xl flex flex-wrap gap-4 mb-4">
        {/* Filters Section */}
        <div className="w-full lg:flex lg:gap-4 lg:items-center lg:-mb-24">
          {/* Protocol Filter */}
          <div className="w-full lg:w-auto">
            <label htmlFor="protocolFilter" className="block text-gray-700 text-left">
              Protocol
            </label>
            <select
              id="protocolFilter"
              name="protocol"
              className="form-select w-full rounded-lg border border-gray-400 text-black"
              onChange={handleFilterChange}
              value={tempFilters.protocol === null ? "all" : tempFilters.protocol}
            >
              <option value="all">All</option>
              <option value="http">HTTP</option>
              <option value="socks4">SOCKS4</option>
            </select>
          </div>

          {/* SSL Filter */}
          <div className="w-full lg:w-auto">
            <label htmlFor="sslFilter" className="block text-gray-700 text-left">
              SSL
            </label>
            <select
              id="sslFilter"
              name="ssl"
              className="form-select w-full rounded-lg border border-gray-400 text-black"
              onChange={handleFilterChange}
              value={tempFilters.ssl === null ? "all" : tempFilters.ssl.toString()}
            >
              <option value="all">All</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>

          {/* Country Filter */}
          <div className="w-full lg:w-auto">
            <label htmlFor="countrySelect" className="block text-gray-700 text-left">
              Country
            </label>
            <select
              id="countrySelect"
              name="country"
              className="form-select w-full rounded-lg border border-gray-400 text-black"
              onChange={handleFilterChange}
              value={tempFilters.country}
            >
              <option value="all">All Countries</option>
              {Object.entries(countryCodes).map(([code, country]) => (
                <option key={code} value={code}>
                  {country}
                </option>
              ))}
            </select>
          </div>

          {/* Apply Filters Button */}
          <div className="w-full lg:w-auto flex justify-center mt-6 lg:mt-6">
            <button
              onClick={handleApplyFilters}
              className="bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition"
            >
              Apply Filters
            </button>
          </div>
        </div>

        {/* Download Section */}
        <div className="w-full lg:w-auto flex flex-col lg:flex-row lg:gap-4 items-center mt-4 lg:mt-6 lg:ml-auto">
          <button
            onClick={handleDownload}
            className="bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-black hover:text-white transition w-full lg:w-auto"
          >
            Download
          </button>

          <select
            onChange={(e) => setDownloadType(e.target.value)}
            value={downloadType}
            className="form-select w-full lg:w-auto rounded-lg border border-gray-400 text-black mt-2 lg:mt-0"
          >
            <option value="all">Download All</option>
            <option value="ip:port">Download IP:Port</option>
          </select>
        </div>
      </div>

      {/* Proxy List */}
      <div className="flex flex-col w-full max-w-5xl bg-white shadow-lg rounded-lg overflow-hidden">
        <ul className="divide-y divide-gray-200 mb-10 flex-grow overflow-auto" style={{ maxHeight: "400px" }}>
          {/* Header Row */}
          <li className="grid grid-cols-1 md:grid-cols-6 gap-4 px-1 py-3 bg-purple-100 text-left text-gray-900 font-semibold border border-gray-200 rounded-lg shadow-md">
            <div className="text-center">PROTOCOL</div>
            <div className="text-center">IP ADDRESS</div>
            <div className="text-center">PORT</div>
            <div className="text-center">ISP</div>
            <div className="text-center">COUNTRY</div>
            <div className="text-center">SSL</div>
          </li>

          {/* Proxy Data Rows */}
          {visibleProxies.map((proxy, index) => (
            <li
              key={index}
              className="grid grid-cols-1 md:grid-cols-6 gap-4 px-1 py-3 hover:bg-gray-100 bg-gray-50 transition-all duration-300"
            >
              <div className="text-center uppercase">{proxy.protocol}</div>
              <div className="text-center">{proxy.ip}</div>
              <div className="text-center">
                <span className="bg-purple-500 text-white px-2 py-1 rounded-lg ">{proxy.port}</span>
              </div>
              <div className="text-center">{proxy.isp}</div>
              <div className="text-center">{countryCodes[proxy.countryCode]}</div>
              <div className="text-center">{proxy.ssl ? "Yes" : "No"}</div>
            </li>
          ))}
        </ul>
        {/* Load More Button */}
        <div className="flex justify-center mb-4 -mt-6 ">
          <button onClick={loadMoreProxies} className="bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition">
            Load More | Remember To Scroll
          </button>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-center space-x-4">
        <p className="text-lg font-normal font-sans">Need Faster Proxies?</p>
        <a
          href={`${process.env.REACT_APP_FRONTEND_URL}/auth/register`}
          className="inline-block px-6 py-2 text-base font-sans text-white bg-purple-400 rounded-lg shadow hover:bg-black transition duration-500"
        >
          Get Free Trial
        </a>
      </div>
    </div>
  );
};

export default ProxyList;
