import React, { useState, useEffect } from "react"; // Import useState and useEffect
import "tailwindcss/tailwind.css"; // Ensure Tailwind CSS is imported
import ProxyList from "../components/ProxyList"; // Import ProxyList component
import Blog from "../components/blog"; // Import Blog component
import Trustpilot from "../assets/Image_5-removebg-preview.png";
import { Helmet } from "react-helmet"; // Import Helmet for managing the document head

const App = () => {
  // Initialize the activeIndex state using useState
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const faqData = [
    {
      question: "What is the difference between HTTP, Socks4, and Socks5 proxies?",
      answer:
        "HTTP proxies are used for browsing the web, while Socks4 and Socks5 proxies can handle more types of traffic like torrenting, gaming, and instant messaging.",
    },
    {
      question: "How often is the proxy list updated?",
      answer: "Our proxy list is updated every 5 minutes to ensure you always get fresh and working proxies.",
    },
    {
      question: "Are the proxies free to use?",
      answer:
        "Yes, we provide free proxies, but we also offer premium proxies with additional features such as higher speed and reliability.",
    },
    {
      question: "Can I use these proxies for streaming?",
      answer: "Yes, these proxies can be used for streaming, but we recommend using our premium proxies for the best performance.",
    },
    {
      question: "Do you offer support for the free proxies?",
      answer: "Yes, we offer support for proxy-related issues via our live chat.",
    },
  ];

  const handleChatOpen = () => {
    if (window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center">
      {/* SEO Metadata */}
      <Helmet>
        <title>Free Premium Proxy List - Updated every 5 minutes</title>
        <meta
          name="description"
          content="Download the best free HTTP, Socks4, and Socks5 proxies. Updated every 5 minutes with working proxies."
        />
        <meta
          name="keywords"
          content="free proxy list, HTTP proxies, Socks4 proxies, Socks5 proxies, proxy list, free proxies, proxy server, AlertProxies, proxy downloader"
        />
        <meta name="author" content="AlertProxies" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Open Graph Metadata for Social Media */}
        <meta property="og:title" content="Free Proxy List - AlertProxies.com" />
        <meta
          property="og:description"
          content="Download the best free HTTP, Socks4, and Socks5 proxies. Updated every 5 minutes with working proxies. Trusted by thousands of users worldwide!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.alertproxies.com/" />
        <meta property="og:image" content="https://www.alertproxies.com/og-image.png" />
        <meta property="og:image:alt" content="AlertProxies Free Proxy List" />
        <meta property="og:image:type" content="image/png" />

        {/* Twitter Card Metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Free Proxy List - AlertProxies.com" />
        <meta
          name="twitter:description"
          content="Download the best free HTTP, Socks4, and Socks5 proxies. Updated every 5 minutes with working proxies. Trusted by thousands of users worldwide!"
        />
        <meta name="twitter:image" content="https://www.alertproxies.com/twitter-image.png" />
        <meta name="twitter:site" content="@AlertProxies" />

        {/* Canonical Link */}
        <link rel="canonical" href="https://www.alertproxies.com/" />

        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />

        {/* Structured Data for Search Engines */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "url": "https://www.alertproxies.com/",
              "name": "AlertProxies",
              "description": "The best free proxy list updated every 5 minutes. Reliable, fast HTTP, Socks4, and Socks5 proxies for everyone!",
              "publisher": {
                "@type": "Organization",
                "name": "AlertProxies"
              }
            }
          `}
        </script>

        {/* Article Structured Data for Blog Section */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Article",
              "headline": "Learn More About Free vs Premium Proxies",
              "description": "Understand the difference between Free and Premium proxies, and learn how to make the best choice for your needs.",
              "author": {
                "@type": "Person",
                "name": "AlertProxies"
              },
              "datePublished": "2024-12-29",
              "publisher": {
                "@type": "Organization",
                "name": "AlertProxies"
              }
            }
          `}
        </script>
      </Helmet>

      <div className="container mx-auto px-4 mt-8 text-center">
        {/* Trustpilot image centered */}
        <div className="flex justify-center -mb-1 mt-24 md:mt-32">
          <img src={Trustpilot} alt="Trustpilot Reviews" className="w-auto max-h-16" />
        </div>
        {/* Header Section */}
        <header className="flex flex-col items-center max-w-[90%] md:max-w-[60%] mx-auto font-inter">
          <h1 className="text-4xl md:text-5xl font-semibold mb-4">Free Proxy List | By AlertProxies</h1>
          <p className="text-gray-700 text-sm md:text-lg">HTTP, Socks4 and Socks5 proxylist | Updated every 5 minutes.</p>
          <div className="mt-6 flex items-center justify-center space-x-4">
            <button
              className="inline-block px-6 py-2 text-base font-inter text-white bg-purple-500 rounded-lg shadow hover:bg-black transition duration-500"
              onClick={() => (window.location.href = "https://dashboard.alertproxies.com/auth/register")}
            >
              Try Premium
            </button>

            <button
              className="inline-block px-6 py-2 text-base font-inter text-purple-500 border border-purple-500 rounded-lg shadow hover:bg-black hover:border-black hover:text-white transition duration-500"
              onClick={handleChatOpen}
            >
              Contact Us
            </button>
          </div>
        </header>

        {/* Proxy List Section */}
        <div className="transform scale-100 md:scale-90 mt-6 md:mt-12">
          <div className="max-w-full md:max-w-[70%] mx-auto ">
            <ProxyList /> {/* Render the ProxyList component */}
          </div>
        </div>

        {/* Video Section */}
        <div className="mt-4 h-[1px] bg-gray-200"></div>
        <div className="mt-12">
          <div className="max-w-full md:max-w-[70%] mx-auto">
            <div className="text-4xl">
              <h2>Free vs Premium Proxies</h2>
            </div>
            <div className="mb-8 text-gray-600 mt-2">
              <p>Learn more about Free and Premium proxies by watching this video!</p>
            </div>
            <div className="relative overflow-hidden rounded-lg shadow-lg" style={{ paddingBottom: "56.25%" }}>
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/-ncGfWlvK1k"
                title="Free Vs Premium Proxies"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <button
            className="mt-8 inline-block px-6 py-2 text-base font-inter text-white bg-purple-400 rounded-lg shadow hover:bg-black transition duration-500"
            onClick={() => (window.location.href = "https://dashboard.alertproxies.com/auth/register")}
          >
            Start Free Trial {">"}
          </button>
        </div>

        <div className=" mt-8 h-[1px] bg-gray-200"></div>
        <div className="max-w-4xl mx-auto space-y-4">
          <div className="text-4xl mt-6">
            <h2>Frequently Asked Questions</h2>
            <div className="mb-8 text-lg text-gray-600 mt-2">
              <p>All your questions answered!</p>
            </div>
          </div>

          {faqData.map((faq, index) => (
            <div
              key={index}
              className={`transition-all duration-300 rounded-lg border ${
                activeIndex === index ? "border-purple-600" : "border-transparent"
              } bg-white shadow-md mt-6 hover:bg-pink-50 transition duration-300`}
            >
              <button
                onClick={() => toggleFAQ(index)}
                className="w-full flex justify-between items-center p-4 text-left text-lg font-medium text-gray-700 focus:outline-none"
                aria-expanded={activeIndex === index}
                aria-controls={`faq-answer-${index}`}
              >
                <span>{faq.question}</span>
                <svg
                  className={`w-6 h-6 transform transition-transform duration-300 ${
                    activeIndex === index ? "rotate-180" : "rotate-0"
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <div id={`faq-answer-${index}`} className={`px-4 pt-2 pb-4 text-gray-700 ${activeIndex === index ? "block" : "hidden"}`}>
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-6 h-[1px] bg-gray-200"></div>
        {/* Blog Section */}
        <div className="max-w-full md:max-w-[70%] mx-auto mt-12">
          <Blog /> {/* Render the Blog component */}
        </div>
      </div>
    </div>
  );
};

export default App;
