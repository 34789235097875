import React, { useContext, useState } from "react";
import SectionHeader from "../components/SectionHeader";
import { SettingsContext } from "../App";

const TOS = () => {
  const settings = useContext(SettingsContext);
  const iframeRef = React.useRef(null);
  const [iframeHeight, setIframeHeight] = useState("100vh"); // Set initial height

  React.useEffect(() => {
    const handlePostMessage = (event) => {
      if (event.data.type === "SET_IFRAME_HEIGHT" && iframeRef.current) {
        setIframeHeight(event.data.height); // Update height based on message
      }
    };

    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  const createIframeContent = () => {
    return `
      <html>
        <head>
       
          <style>
            
            body { margin: 0;  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; }
            .content { padding: 20px; }
          </style>
        </head>
        <body >
          <div class="content" id="tosContent">${settings?.TOS}</div>
          <script>
            function sendHeight() {
              const height = document.getElementById('tosContent').scrollHeight;
              window.parent.postMessage({ type: "SET_IFRAME_HEIGHT", height: height }, '*');
            }

            window.onload = sendHeight;
            // window.onresize = sendHeight; // Optional: to handle window resizing
          </script>
        </body>
      </html>
    `;
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="container mx-auto px-4 py-8 sm:px-6 md:px-8 lg:px-12 xl:px-20 mt-20 sm:mt-28">
        <div>
          <SectionHeader
            headerInfo={{
              title: "Terms Of Service",
              subtitle: "Please Read It Carefully!",
            }}
          />
        </div>

        <iframe
          ref={iframeRef}
          className="w-full"
          style={{ height: iframeHeight }} // Set dynamic height
          srcDoc={createIframeContent()} // Set the iframe content
          title="Terms Of Service"
        />
      </div>
    </div>
  );
};

export default TOS;
