import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const BlogItem = ({ blog }) => {
  const { mainImage, title, metadata, author, publishedDate, id } = blog;

  return (
    <div className="rounded-lg border border-purple-300 bg-white p-4 pb-4 shadow-solid-8 dark:bg-blacksection hover:bg-purple-100 transition duration-500">
      <Link to={`/blog/${id}`} className="relative block aspect-[16/9] mb-4">
        <img
          src={mainImage || "https://via.placeholder.com/368x239?text=No+Image"}
          alt={title || "Blog Post"}
          className="object-cover w-full h-full rounded-lg"
        />
      </Link>

      <div className="px-4">
        {/* Author and Published Date Section */}
        <div className="mb-4 text-sm text-gray-600 flex items-center space-x-4">
          <p className="font-semibold">{author}</p>
          <span>&bull;</span>
          <p>{publishedDate}</p>
        </div>

        <h3 className="mb-2 -mt-1 line-clamp-2 inline-block text-lg font-medium text-black duration-300 hover:text-primary dark:hover:text-primary xl:text-itemtitle2">
          <Link to={`/blog/${id}`}>{title}</Link>
        </h3>

        <p className="line-clamp-3">{metadata}</p>
      </div>

      {/* Meta Description for SEO */}
      <Helmet>
        <meta name="description" content={metadata} />
      </Helmet>
    </div>
  );
};

export default BlogItem;